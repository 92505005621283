import React from 'react';
import 'normalize.css';
import 'prismjs/themes/prism.css';
import styled from 'styled-components';
import GlobalStyles from '../styles/GlobalStyles';
import Typography from '../styles/Typography';
import Footer from './Footer';
import Header from './Header';

const WrapperStyles = styled.div`
  margin: var(--spacing-0) auto;
  max-width: var(--maxWidth-wrapper);
  padding: var(--spacing-10) var(--spacing-5);
`;

export default function Layout({ children, location }) {
  const isTopLevel = location.pathname === '/';
  return (
    <WrapperStyles className="global-wrapper">
      <GlobalStyles />
      <Typography />
      <Header isTopLevel={isTopLevel} />
      <main>{children}</main>
      <Footer />
    </WrapperStyles>
  );
}
