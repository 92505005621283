import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const HeaderStyles = styled.header`
  margin-bottom: var(--spacing-12);
`;

const MainHeadingStyles = styled.h1`
  font-size: var(--fontSize-7);
  margin: 0;
`;

const LinkHomeStyles = styled(Link)`
  font-weight: var(--fontWeight-bold);
  font-family: var(--font-heading);
  text-decoration: none;
  font-size: var(--fontSize-2);
`;

export default function Header({ isTopLevel }) {
  const title = "Broom's Bible Corner";

  if (isTopLevel) {
    return (
      <HeaderStyles>
        <MainHeadingStyles>
          <Link to="/">{title}</Link>
        </MainHeadingStyles>
      </HeaderStyles>
    );
  }
  return (
    <HeaderStyles>
      <LinkHomeStyles to="/">{title}</LinkHomeStyles>
    </HeaderStyles>
  );
}
